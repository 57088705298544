require('./bootstrap');
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'

// Ziggy start here
import route from 'ziggy'
import { Ziggy } from './ziggy'


InertiaProgress.init({
  delay: 250,
  color: '#29d',
  includeCSS: true,
  showSpinner: true,
});

createInertiaApp({
  id: 'app',
  resolve: name => import(`./Pages/${name}`).then(module => module.default),
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .mixin({ methods: { route: window.route } })
      //.mixin({ methods: { route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config) }})
      .mount(el)
  },
});

/*import { createApp, h } from "vue";
import { App, plugin } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";

InertiaProgress.init();

const el = document.getElementById("app");

createApp({
	metaInfo: {
		titleTemplate: title => (title ? `${title} - e-Yantra` : 'e-Yantra Robotics Competition 2021-22'),
	},
	render: () =>
	h(App, {
		initialPage: JSON.parse(el.dataset.page),
		resolveComponent: (name) => require(`./Pages/${name}`).default,
	}),
})
.use(plugin)
.mixin({ methods: { route: window.route } })
.mount(el);
*/
